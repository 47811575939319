import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function REU2022Page(props) {
  return (
    <Layout>
      <SEO title="REU 2022 Registration" />
      <iframe
        class="airtable-embed"
        src="https://airtable.com/embed/shrOeXLOHHxnOzmJm?backgroundColor=blue"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="533"
        style={{
          background: "transparent",
          border: "1px solid #ccc",
          marginTop: "100px",
          height: "calc(100vh - 100px)",
          overflow: "auto",
        }}
      ></iframe>
    </Layout>
  )
}
